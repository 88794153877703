import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { UserService } from "../core/services/user/user.service";

@Component({
	selector: "app-impersonate",
	templateUrl: "./impersonate.component.html",
	styleUrls: ["./impersonate.component.scss"]
})
export class ImpersonateComponent implements OnInit {
	private email: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private userService: UserService
	) {
		this.route.params.subscribe(params => (this.email = params["email"]));
	}

	ngOnInit() {
		console.log(this.email);
		this.userService
			.impersonate(this.email)
			.pipe(first())
			.subscribe(
				data => {
					this.router.navigate(["/"]);
				},
				error => {
					console.log(error);
				}
			);
	}
}
