import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { errorsMessages } from "../../config";

@Component({
	selector: "boost-input",
	template: `
		<div class="boost-input_form-group" [formGroup]="form">
			<label>{{ label }}</label>
			<div class="{{ showToggleButton ? 'hasIcon' : '' }}">
				<input
					[type]="type"
					class="boost-input_form-control"
					[autocomplete]="autocomplete ? autocomplete : controlName"
					[formControlName]="controlName"
					[autofocus]="autofocus"
				/>
				<div
					(click)="togglePassWord()"
					*ngIf="showToggleButton"
					class="boost-input_form-control-icon"
				>
					<span class="material-icons-outlined" *ngIf="!showPassWord">
						visibility
					</span>
					<span class="material-icons-outlined" *ngIf="showPassWord">
						visibility_off
					</span>
				</div>
			</div>
			<p
				*ngIf="
					form.get(controlName)?.invalid &&
					(form.get(controlName)?.touched || form.get(controlName)?.dirty)
				"
			>
				<span>{{ errorMessage() }}</span>
			</p>
		</div>
	`
})
export class BoostInputComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() controlName: string;
	@Input() label: string;
	@Input() autofocus: boolean;
	@Input() autocomplete: string;
	@Input() type: "text" | "email" | "password";

	public showToggleButton: boolean;
	public showPassWord: boolean;
	private control: AbstractControl;
	public errorsMessages: any;
	public defaultMessage = errorsMessages["default"];
	constructor() {}
	ngOnInit() {
		this.showToggleButton = this.type === "password";
		this.errorsMessages = errorsMessages[this.controlName];
		this.control = this.form.controls[this.controlName];
	}

	public togglePassWord() {
		this.showPassWord = !this.showPassWord;
		this.type = this.type === "password" ? "text" : "password";
	}
	public errorMessage = (): string => {
		if (!this.errorsMessages) return this.defaultMessage;
		const controlErrors = this.control?.errors;
		if (!controlErrors) return null;
		const errorName = Object.keys(controlErrors)[0].toLowerCase();
		if (!errorName) return this.defaultMessage;
		return this.errorsMessages[errorName] || this.defaultMessage;
	};
}
