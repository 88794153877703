import { Injectable } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { urlParams } from "../../app.interface";

@Injectable({ providedIn: "root" })
export class NavigationService {
	/// MODAL
	private readonly _openModal: BehaviorSubject<any> = new BehaviorSubject(null);

	public readonly openModal$: Observable<any> = this._openModal.asObservable();

	public set openModal(data) {
		this._openModal.next(data);
	}
	public get openModal() {
		return this._openModal.getValue();
	}

	/// URL READ
	private readonly _routeParams = new BehaviorSubject<urlParams>(null);

	public readonly routeSnapshot$: Observable<any> = this.router.events.pipe(
		filter(event => event instanceof ActivationStart),
		map((event: ActivationStart) => event.snapshot)
		//shareReplay()
	);

	public get routeParams(): urlParams {
		return this._routeParams.getValue() ?? {};
	}

	public routeParams$: Observable<any> = this._routeParams.asObservable().pipe(
		filter(params => !!params),
		distinctUntilChanged()
	);
	constructor(private router: Router) {
		this.routeSnapshot$
			.pipe(
				//	tap(snapshot => console.log("snapshot: ", snapshot)),
				map(snapshot => ({
					...snapshot.params,
					...snapshot.queryParams
				})),
				//		tap(params => console.log("params: ", params)),

				tap(params => this._routeParams.next(params))
			)
			.subscribe();
	}
}
