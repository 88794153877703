import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TruncateModule } from "ng2-truncate";
import { NgxPopperModule } from "ngx-popper";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { InfoTooltipComponent } from "./info-tooltip/info-tooltip.component";
import { IssueIconComponent } from "./issue-icon/issue-icon.component";
import { ModalComponent } from "./modal/modal.component";
import { popinConfirmComponent } from "./popin-confirm/popin-confirm.component";
import { StepperComponent } from "./stepper/stepper.component";

@NgModule({
	imports: [CommonModule, RouterModule, TruncateModule, NgxPopperModule],
	declarations: [
		BreadcrumbComponent,
		popinConfirmComponent,
		IssueIconComponent,
		ModalComponent,
		CheckboxComponent,
		StepperComponent,
		FooterComponent,
		HeaderComponent,
		InfoTooltipComponent
	],
	exports: [
		BreadcrumbComponent,
		popinConfirmComponent,
		IssueIconComponent,
		ModalComponent,
		CheckboxComponent,
		StepperComponent,
		FooterComponent,
		HeaderComponent,
		InfoTooltipComponent
	]
})
export class BoostComponentsModule {}
