import { gql } from "apollo-angular";

export const getFundForComparaison = gql`
	query RefFund($isin: String!) {
		RefFund(isin: $isin) {
			name
			isin
			varP1M
			varP3M
			varP6M
			varP1Y
			varP2Y
			varP3Y
			varP5Y
			varP10Y

			volat1Y
			volat3Y
			volat5Y

			risk
			RefFundValues {
				vl
				timestamp
			}
		}
	}
`;

export const getFund = gql`
	query RefFund($isin: String!) {
		RefFund(isin: $isin) {
			id
			name
			isin
			assetMgtCompanyLabel
			categoryOpcvmLibelle
			lastVl_formatted
			risk
			varPLast_formatted
			RefFundValues {
				vl
				vl_formatted
				date
				timestamp
			}
		}
	}
`;

export const searchFunds = gql`
	query searchFunds($filter: String!) {
		searchFunds(filter: $filter) {
			id
			name
			isin
			categoryOpcvmLibelle
			categoryOpcvm
			lastVl_formatted
			risk
			varPLast_formatted
			varP1Y
			varP3Y
			varP5Y

			volat1Y
			volat3Y
			volat5Y
			owned
		}
	}
`;

export const getRefContracts = gql`
	query RefContractsByBankId($bankId: Int!) {
		RefContractsByBankId(bankId: $bankId) {
			label
			value
		}
	}
`;
