import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { TermsComponent } from "./auth/terms/terms.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { NotFoundComponent } from "./core/NotFoundComponent.page.component";
import { ImpersonateComponent } from "./impersonate/impersonate.component";

const routes: Routes = [
	// WTF

	{ path: "impersonate/:email", component: ImpersonateComponent },
	{ path: "terms", component: TermsComponent },

	/* AUTH */
	{
		path: "auth",
		loadChildren: () =>
			import("./auth/auth.module").then(m => m.InscriptionModule)
	},

	/* PROFILE */
	{
		path: "profile",
		canActivate: [AuthGuard],
		loadChildren: () =>
			import("./profile/profile.module").then(m => m.ProfileModule)
	},

	/* ACCOUNTS */
	{
		path: "account",
		canActivate: [AuthGuard],

		loadChildren: () =>
			import("./accounts/accounts.module").then(m => m.AccountModule)
	},
	{
		path: "add-account",
		canActivate: [AuthGuard],
		outlet: "modal",

		loadChildren: () =>
			import("./add-account/add-account.module").then(m => m.AddAccountModule)
	},

	/* CONTRACTS  */
	{
		path: "contract",
		loadChildren: () =>
			import("./contracts/contracts.module").then(m => m.ContractsModule)
	},

	/* FUNDS */

	{
		path: "fund",
		canActivate: [AuthGuard],
		outlet: "modal",
		loadChildren: () => import("./funds/funds.module").then(m => m.FundsModule)
	},

	/* DEFAULT */

	{
		path: "dashboard",
		canActivate: [AuthGuard],
		//	pathMatch: "full",
		loadChildren: () =>
			import("./dashboard/dashboard.module").then(m => m.DashboardModule)
	},
	{
		path: "",
		pathMatch: "full",
		redirectTo: "dashboard"
	},
	// { path: "**", redirectTo: "dashboard" },
	{ path: "404", component: NotFoundComponent },
	{ path: "**", redirectTo: "/404" }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "enabled",
			relativeLinkResolution: "corrected",
			preloadingStrategy: PreloadAllModules
			//	enableTracing: true
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
