<div class="page-container">

  <app-header *ngIf="(isAuthenticated$ | async) &&  (profile$ | async) && !(openModal | async)" [me]="profile$ | async"></app-header>


  <main class="main-content" id="main-content">
    <router-outlet></router-outlet>
    <router-outlet
      name="modal"
      (activate)="activateModal($event)"
      (deactivate)="deactivateModal($event)"
    >
    </router-outlet>
  </main>
  <app-footer></app-footer>



  <div *ngIf=" (profile$ | async)"
    class="popInOverlay {{
      (showConnectionsIssue$ | async) || (showContractSetting$| async)? 'open' : 'closed'
    }} "
  >
    <article id="alertBox" *ngIf="(showConnectionsIssue$ | async)">

      <header>
        <span class="material-icons close" (click)="closeModal()"> close </span>
        <h1>Oups :/</h1>
        <p>
          Pour continuez à profiter de tous les avantages BOOST épargne suivez les insructions ci-dessous.
        </p>
      </header>
      <main>

        <connections-Issues></connections-Issues>
      </main>
      <footer>
        <a class="link"
        [routerLink]="['account']"
      (click)="closeModal()">Voir toutes mes connections</a>
    </footer>
    </article>

    <article id="alertBox" *ngIf="(showContractSetting$ | async) as contract">

      <header>
        <span class="material-icons close" (click)="closeModal()"> close </span>
        <h1>{{contract.contractName}}</h1>
      </header>
      <main>
        <account-form [contract]=contract></account-form>
      </main>
      <footer>
        <a class="link"
        [routerLink]="[
        'account',
        contract.connectionId,
        contract.contractBIId
        ]"
      (click)="closeModal()">acceder à la page de l'établissement</a> </footer>
    </article>
  </div>

</div>
