<div>
  <div>
    <p>Copyright © {{year}} Boost Epargne Tous droits réservés.</p>
  </div>

  <div>
    <a href="#" [routerLink]="['/terms']">Conditions Générales d'utilisation</a>
    <a href="#">Politique de confidentialité</a>
  </div>

</div>
