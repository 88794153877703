import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import * as Sentry from "@sentry/browser";
import { NgxPopperModule } from "ngx-popper";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { ConnectionService } from "./core/services/connection.service";
import { NavigationService } from "./core/services/navigation.service";
import { ImpersonateComponent } from "./impersonate/impersonate.component";
import { BoostComponentsModule } from "./shared/components/boost-components.module";
import { PopinProfilDetailsComponent } from "./shared/components/popin-profil-details/popin-profil-details.component";
import { BoostFormsModule } from "./shared/forms/forms.module";
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeFr);

if (environment.envName !== "local") {
	Sentry.init({
		dsn: "https://8bd57f5d87674006a54ce44b7c3ed4fc@sentry.io/1441559",
		environment: environment.envName
	});
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	constructor() {}

	handleError(error) {
		const eventId = Sentry.captureException(error.originalError || error);
		// Sentry.showReportDialog({
		//   eventId,
		//   title: 'Houston, nous avons un problème...'
		// });
	}
}

@NgModule({
	declarations: [
		AppComponent,
		ImpersonateComponent,
		PopinProfilDetailsComponent
	],
	imports: [
		CoreModule,
		NgxPopperModule.forRoot({}),
		BrowserModule,
		CommonModule,
		AppRoutingModule,
		BoostFormsModule,
		BoostComponentsModule,
		HttpClientModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    // Register the ServiceWorker as soon as the app is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  })
	],
	providers: [NavigationService, { provide: LOCALE_ID, useValue: "fr-FR" }],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private CONNCECT: ConnectionService) {}
}
