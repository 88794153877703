<ng-container *ngIf="form">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <ng-container *ngFor="let field of connection.fields">
      <boost-input *ngIf="field.type !== 'list';else select" [form]="form" [type]="field.type"
        [controlName]="field.name" [label]="field.label" [autofocus]="true" [autocomplete]="'new-'+field.name">
      </boost-input>

      <ng-template #select>

        <boost-select [form]="form" [controlName]="field.name" [label]="field.label" [items]="field.values"
          [clearable]="false"></boost-select>



      </ng-template>
    </ng-container>
    <boost-submit-button [form]="form" [loading]="loading" [label]="'Valider'" [theme]="'login'"></boost-submit-button>
  </form>
</ng-container>
