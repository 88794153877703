import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { share } from "rxjs/operators";
import {
	getFund,
	getFundForComparaison,
	getRefContracts,
	searchFunds
} from "../../graphql/queries/RefData";
import { toInt } from "../utils.service";

@Injectable({
	providedIn: "root"
})
export class RefDataService {
	constructor(private apollo: Apollo) {}

	public getFund(isin) {
		return this.apollo.watchQuery<any>({
			query: getFund,
			variables: {
				isin: isin
			}
			// fetchPolicy: 'network-only'
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}
	public getFundForComparaison(isin) {
		return this.apollo.watchQuery<any>({
			query: getFundForComparaison,
			variables: {
				isin: isin
			}
		}).valueChanges;
	}

	public searchFunds(filter) {
		return this.apollo
			.watchQuery<any>({
				query: searchFunds,
				variables: {
					filter: filter
				},
				// fetchPolicy: 'network-only'
				fetchPolicy: "cache-first"
			})
			.valueChanges.pipe(share());
	}

	public getRefContracts(bankId) {
		return this.apollo.watchQuery<any>({
			query: getRefContracts,
			variables: {
				bankId: toInt(bankId)
			},
			fetchPolicy: "cache-first"
		}).valueChanges;
	}
}
