


  <ng-container *ngIf="mainLoading;else formBlock">
    <div class="loading-box"><span class="loader primary"></span></div>
  </ng-container>

  <ng-template #formBlock>


  <form [formGroup]="form" *ngIf="!disabledAccount;else disabledForm">
    <boost-input
    [form]="form"
    [type] = "'text'"
    [controlName]="'contractName'"
    [label]="'Nom du contract'"
    [autofocus]="true"
    ></boost-input>

    <boost-input
     *ngIf="isSalarialAccount"
    [form]="form"
    [type] = "'text'"
    [controlName]="'companyName'"
    [label]="'Entreprise'"
    ></boost-input>

    <boost-select
    [form]="form"
    [controlName]="'produit'"
    [label]="'Produit'"
    [items]="produitOption$ | async"
    ></boost-select>

    <boost-select
    [form]="form"
    [controlName]="'projects'"
    [label]="'Projets'"
    [items]="projectOptions"
    [multiple] = "true"
    ></boost-select>


    <div >
   <!-- <boost-checkbox
      #freeManagementCB
      [form]="form"
      [controlName]="'freeManagement'"
      [label]="'Gestion libre'"></boost-checkbox>
-->
      <span  [ngClass]="{'loading':loading}" class="loader primary full"></span>
    </div>

   <!-- <boost-submit-button [form]="form" [loading]="loading" [label]="'Se connecter'"></boost-submit-button>-->
  </form>

  <ng-template #disabledForm>

    <div class="toggleBox">
      <div>
      <input type="checkbox" id="toggleAccount" class="toggle" [checked]="!disabledAccount" (change)="toggleActivation()" />
      <label for="toggleAccount" aria-describedby="label"> </label>
      </div>
      <p>J'active mon compte</p>
    </div>
  </ng-template>

  <div class="feedback {{feedback}}">
    <span class="feed_danger">Une erreur est survenue, veuillez réésayer plus tard.</span>
    <span class="feed_success">Contract mis à jour avec success :)</span>
  </div>

</ng-template>

