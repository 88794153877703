import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { getInstance } from "../graphql/queries/Others";

@Injectable({
	providedIn: "root"
})
export class ThemeService {
	private _active: Theme;

	public get active() {
		return this._active;
	}

	public getActiveTheme() {
		return this._active;
	}

	constructor(private apollo: Apollo) {
		if (!this.active) this.setActiveTheme("default");
	}

	public setActiveTheme(themeName: string): void {
		// this.getInstance().subscribe(({ data }) => {
		// console.log(data)
		// themeName = data.getInstance.slug;
		const theme = themes.find(({ name }) => name === themeName);
		this._active = theme;

		Object.keys(this._active.colors).forEach(property => {
			document.documentElement.style.setProperty(
				property,
				this._active.colors[property]
			);
		});
		// });
	}

	public getInstance() {
		return this.apollo.watchQuery<any>({
			query: getInstance,
			fetchPolicy: "network-only"
		}).valueChanges;
	}
}

export interface Theme {
	name: string;
	colors: any;
	logo: string;
	logo_top: string;
	baseline?: string;
}

export const themes: Theme[] = [
	{
		name: "default",
		logo: "logo-boost-white.svg",
		logo_top: "logo-boost.svg",
		baseline: "L'intelligence collective <br> au service de votre épargne",
		colors: {
			"--default-primary": "#4675b8"
		}
	},
	{
		name: "axa",
		logo: "logo-axa.svg",
		logo_top: "logo-axa.svg",
		colors: {
			"--default-primary": "#00008F"
		}
	},
	{
		name: "bnp",
		logo: "logo-bnp-big.svg",
		logo_top: "logo-bnp-big.svg",
		colors: {
			"--default-primary": "#02925d"
		}
	}
];
