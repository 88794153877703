import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from "@angular/router";
import { AuthenticationService } from "../services/authentication/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private AUTH: AuthenticationService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		// console.log("canActivate: ", next, state);
		// console.log("this.AUTH.isAuthenticated: ", this.AUTH.isAuthenticated);
		if (!this.AUTH.isAuthenticated) {
			this.router.navigate(["/auth/login"], {
				queryParams: { returnUrl: state.url }
			});
			return false;
		} else {
			return true;
		}
	}
}
