import { gql } from "apollo-angular";

export const getMe = gql`
	query getMe {
		me {
			id
			email
			nickname
			role
			is_admin
			Connections {
				id
				connectionId
				error
				Bank {
					id
					name
					slug
				}
			}
		}
	}
`;

export const getProfile = gql`
	query getProfile {
		me {
			id
			email
			nickname
			role
			is_admin
			age
			csp
			biToken
		}
	}
`;
/*
	Follow {
				id
				refContractName
			}
			Contracts {
				id
				contractBIId
				contractName
				refContractName
			}
*/
export const getDashboard_w_commu = gql`
	query getDashBoard {
		me {
			id
			email
			nickname
			role
			is_admin
			Contracts {
				id
				connectionId
				contractBIId
				contractName
				refContractName
				refContractId
				followers
				freeManagement
				User {
					id
					nickname
				}
				FollowSameKind {
					id
					refContractName
					User {
						id
						nickname
					}
					Bank {
						id
						name
						slug
					}
					ContractLastUpdate {
						id
						risk
						tri_formatted
						tri {
							tauxAnnuel
						}
						position
						totalSameKind
					}
				}
				Bank {
					id
					name
					slug
				}
				ContractFirstUpdate {
					id
					last_update
				}
				ContractLastUpdate {
					id
					type
					idCompanyPromoter
					last_update
					valuation_formatted
					valuation
					risk
					tri_formatted
					tri {
						tauxAnnuel
					}
					position
					totalSameKind
					earnings
					earnings_formatted
				}
			}
		}
	}
`;
/*
	id
					contractName
					risk
          arbitrage
          	valuation
            ContractValues {
					timestamp

					tri {
						tauxAnnuel
					}
				}


        	ContractValues {
						timestamp
						tri {
							tauxAnnuel
						}
					}
*/

export const getDashboard = gql`
	query getDashBoard {
		me {
			id
			email
			nickname
			role
			is_admin
			Contracts {
				id
				connectionId
				contractBIId
				contractName
				refContractName
				refContractId
				followers
				freeManagement
				User {
					id
					nickname
				}

				Bank {
					id
					name
					slug
				}
				ContractFirstUpdate {
					id
					last_update
				}
				ContractLastUpdate {
					id
					type
					idCompanyPromoter
					valuation_formatted
					valuation
					risk
					tri_formatted
					tri {
						tauxAnnuel
					}
					earnings
					earnings_formatted
					last_update
				}
			}
		}
	}
`;
