import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, first, map, share } from "rxjs/operators";
import { Contract } from "../../../app.interface";
import {
	bestContracts,
	compareContracts,
	followContract,
	getAvailableInsurers,
	getAvailablePromoters,
	getContract,
	getContractByBIId,
	getContractsByRef,
	getContractValues,
	getLastContracts,
	getOtherContract,
	getOtherContractValues,
	getTierContract,
	searchContracts,
	unFollowContract,
	worstContracts
} from "../../graphql/queries/Contract";
import { getDashboard } from "../../graphql/queries/User";
import { NavigationService } from "../navigation.service";

@Injectable({ providedIn: "root" })
export class ContractService {
	private readonly _showContractSettings: BehaviorSubject<Contract> =
		new BehaviorSubject<Contract>(null);

	public readonly showContractSetting$: Observable<Contract> =
		this._showContractSettings.asObservable();

	public get showContractSettings(): Contract {
		return this._showContractSettings.getValue();
	}

	public set showContractSettings(val: Contract) {
		this._showContractSettings.next(val);
	}

	private readonly _currentContract: BehaviorSubject<Contract> =
		new BehaviorSubject(null);

	public readonly currentContract$: Observable<Contract> = this._currentContract
		.asObservable()
		.pipe(share());

	public get currentContract() {
		return this._currentContract.getValue();
	}

	public set currentContract(contract: Contract) {
		this._currentContract.next(contract);
	}

	constructor(private apollo: Apollo, private NAV: NavigationService) {
		this.NAV.routeParams$
			.pipe(filter(params => !!params?.contractId))
			.subscribe((params: any) => this.selectContract(params.contractId));
	}

	public selectContract(id: string) {
		if (!id || this.currentContract?.id === id) return;
		this.getContract(id, false)
			.pipe(
				first(),
				map(({ data }) => data?.Contract)
			)
			.subscribe((contract: Contract) => (this.currentContract = contract));
	}

	public getContract(id: string, isOtherContract: boolean) {
		let query = isOtherContract ? getOtherContract : getContract;

		return this.apollo.watchQuery<any>({
			query: query,
			variables: { id },
			fetchPolicy: "cache-and-network"
			//fetchPolicy: "network-only"
		}).valueChanges;
	}

	public getContractValues(id: string, limit: number) {
		return this.apollo.watchQuery<any>({
			query: getContractValues,
			variables: { id, limit },
			fetchPolicy: "cache-and-network"
			//fetchPolicy: "network-only"
		}).valueChanges;
	}
	public getOtherContractValues(id: string, limit: number) {
		return this.apollo.watchQuery<any>({
			query: getOtherContractValues,
			variables: { id, limit },
			fetchPolicy: "cache-and-network"
			//fetchPolicy: "network-only"
		}).valueChanges;
	}
	public saveContract(contract: any): any {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveContract($contract: DataObjectType!) {
						saveContract(contract: $contract)
					}
				`,
				variables: {
					contract: contract
				}
			})
			.pipe(
				map(result => {
					// console.log(result);
					return result;
				})
			);
	}

	public deleteContract(connectionId: any) {
		// String !!
		console.log(typeof connectionId);
		connectionId = Number(connectionId);
		return this.apollo
			.mutate({
				mutation: gql`
					mutation deleteContract($connectionId: Int!) {
						deleteContract(connectionId: $connectionId)
					}
				`,
				variables: {
					connectionId
				}
			})
			.pipe(
				map(result => {
					// console.log(result);
					return result;
				})
			);
	}

	public getContractsByRef(refContractId: string) {
		return this.apollo.watchQuery<any>({
			query: getContractsByRef,
			variables: { refContractId }
			// fetchPolicy: 'network-only'
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}

	public getLastContracts(limit: number = 6) {
		return this.apollo.watchQuery<any>({
			query: getLastContracts,
			variables: { limit }
		}).valueChanges;
	}

	public getContractByBIId(BIId: number) {
		//	console.log("getContractByBIId: ", BIId);
		return this.apollo.watchQuery<any>({
			query: getContractByBIId,
			variables: { BIId },
			fetchPolicy: "network-only" // besoin de réactivité sur l'edition de contrat
			//fetchPolicy: "cache-and-network"
		}).valueChanges;
	}

	public searchContracts(filter: any) {
		return this.apollo.watchQuery<any>({
			query: searchContracts,
			variables: { filter },
			fetchPolicy: "network-only"
			//fetchPolicy: "cache-and-network"
			//fetchPolicy: "cache-first"
		}).valueChanges;
	}

	public getTierContract(id: string) {
		return this.apollo.watchQuery<any>({
			query: getTierContract,
			variables: { id },
			fetchPolicy: "network-only"
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}

	public getTopContracts(top: "best" | "worst", refContractId?, risk?) {
		const method =
			top === "best"
				? this.bestContracts(refContractId, risk)
				: this.worstContracts(refContractId, risk);

		return method.pipe(
			map(({ data, loading }) => ({
				loading,
				data: data.bestContracts || data.worstContracts
			}))
		);
	}

	public bestContracts(refContractId: string, risk: number) {
		const variables =
			refContractId && risk
				? {
						refContractId,
						risk,
						limit: 3
				  }
				: {
						refContractId: null,
						risk: null,
						limit: 3
				  };
		return this.apollo.watchQuery<any>({
			query: bestContracts,
			variables
			// fetchPolicy: 'network-only'
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}

	public worstContracts(refContractId: string, risk: number) {
		const variables =
			refContractId && risk
				? {
						refContractId,
						risk,
						limit: 3
				  }
				: {
						refContractId: null,
						risk: null,
						limit: 3
				  };
		return this.apollo.watchQuery<any>({
			query: worstContracts,
			variables
			// fetchPolicy: 'network-only'
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}

	public compareContracts(compareContractsIds: string[]) {
		return this.apollo.watchQuery<any>({
			query: compareContracts,
			variables: { compareContractsIds }
			// fetchPolicy: 'network-only'
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}

	public followToggle(
		contractID: string,
		isFollower: boolean,
		refresh?: { dashboard?: boolean; userContractId?: string }
	) {
		const query = isFollower
			? this.unFollowContract(contractID)
			: this.followContract(contractID, refresh);

		return query.pipe(first()).subscribe();
	}

	public followContract(
		tiersContractId: string,
		refresh?: { dashboard?: boolean; userContractId?: string }
	) {
		const refetchQueries: any[] = [
			{
				query: getTierContract,
				variables: {
					id: tiersContractId
				}
			}
		];

		if (refresh?.dashboard)
			refetchQueries.push({
				query: getDashboard
			});
		if (refresh?.userContractId)
			refetchQueries.push({
				query: getContract,
				variables: {
					id: refresh.userContractId
				}
			});

		return this.apollo.mutate({
			mutation: followContract,
			variables: {
				contractId: tiersContractId
			},
			refetchQueries
		});
	}

	public unFollowContract(
		tiersContractId: string,
		refresh?: { dashboard?: boolean; userContractId?: string }
	) {
		const refetchQueries: any[] = [
			{
				query: getTierContract,
				variables: {
					id: tiersContractId
				}
			}
		];
		if (refresh?.dashboard)
			refetchQueries.push({
				query: getDashboard
			});
		if (refresh?.userContractId)
			refetchQueries.push({
				query: getContract,
				variables: {
					id: refresh?.userContractId
				}
			});

		return this.apollo.mutate({
			mutation: unFollowContract,
			variables: {
				contractId: tiersContractId
			},
			refetchQueries
		});
	}

	public getAvailablePromoters() {
		return this.apollo.watchQuery<any>({
			query: getAvailablePromoters,
			fetchPolicy: "cache-first"
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}

	public getAvailableInsurers() {
		return this.apollo.watchQuery<any>({
			query: getAvailableInsurers,
			fetchPolicy: "cache-first"
			// fetchPolicy: 'cache-and-network'
		}).valueChanges;
	}
}
