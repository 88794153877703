import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpOptions } from "@apollo/client/core";
import { Jose } from "jose-jwe-jws";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import {
	filter,
	first,
	map,
	share,
	shareReplay,
	switchMap,
	tap
} from "rxjs/operators";
import { BiAccount, Connection } from "src/app/app.interface";
import { environment } from "../../../../environments/environment";
import { Bank } from "../../../app.interface";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class BudgetinsightService {
	private css = "color:#8e44ad;font-weight:bold";
	constructor(private HTTP: HttpClient, private AUTH: AuthenticationService) {
		// this.AUTH.isAuthenticated$.pipe(distinctUntilChanged()).subscribe(auth => {
		// 	if (!this.isInit) this.initBI();
		// 	else if (auth) this.generateHeaders();
		// 	else this.apollo.client.resetStore();
		// });
	}

	public connection$: Observable<Connection[]>;
	// AUTH
	public authInit() {
		return this.HTTP.post<any>(`${environment.biAPIUrl}/auth/init`, {});
	}
	private httpOptions: HttpOptions;
	// TOKEN
	private _token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public get token(): string {
		return this._token.getValue();
	}
	public set token(token: string) {
		this.httpOptions = {
			headers: new HttpHeaders({
				Authorization: token ? `Bearer ${token}` : ""
			})
		};
		this._token.next(token);
		this.initBI();
	}

	public getCode(authToken: any) {
		// if token defined use it
		if (this._token) {
			return of(this._token);
		} // else request

		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: "Bearer " + authToken
			})
		};

		return this.HTTP.get<any>(
			`${environment.biAPIUrl}/auth/token/code`,
			httpOptions
		).pipe(
			map(({ code }) => code),
			tap(code => (this._token = code))
		);
	}
	public getPermanentToken(code: string) {
		const data = {
			client_id: environment.biClientId,
			client_secret: environment.biClientSecret,
			code: code
		};

		return this.HTTP.post<any>(
			`${environment.biAPIUrl}/auth/token/access`,
			data
		).pipe(tap(() => console.log("%cBI getPermanentToken", this.css)));
	}

	//REFRESH
	private readonly _refresh: BehaviorSubject<number> = new BehaviorSubject<number>(
		0
	);
	public readonly refresh$: Observable<number> = this._refresh
		.asObservable()
		.pipe(
			filter(val => val !== 0),
			share(),
			tap(r => console.log("%cREFRESH BI", this.css))
		);

	public refresh() {
		this._refresh.next(this._refresh.getValue() + 1);
	}

	public close() {
		this._token.next(null);
	}
	public token$: Observable<string> = this._token.asObservable().pipe(
		switchMap((token: string) => {
			if (!token) {
				return this.authInit().pipe(map(data => data.auth_token));
			} else {
				return this.getCode(token).pipe(map(data => data));
			}
		})
	);
	public profile$: Observable<any> = this.token$.pipe(
		switchMap(() => this.GETBI(`${environment.biAPIUrl}/users/me/profiles/me`)),
		shareReplay()
	);
	private isInit: boolean;

	public initBI() {
		console.log("%cBI init", this.css);
		this.connection$ = this.mainGET(`users/me/connections?expand=bank`).pipe(
			map(({ connections }) => connections),
			shareReplay()
		);
		// this.profile$ = this.mainGET("users/me/profiles/me", `profile`, false).pipe(
		// 	shareReplay()
		// );
		// this.profile$ = this.GETBI(
		// 	`${environment.biAPIUrl}/users/me/profiles/me`
		// ).pipe(shareReplay());

		// this.profile$ = this.HTTP.get<any>(
		// 	`${environment.biAPIUrl}/users/me/profiles/me`,
		// 	this.httpOptions
		// ).pipe(shareReplay());

		this.isInit = true;
	}

	// COMMONS
	private endpointURL(path, addUserId) {
		console.log({ path, addUserId });
		const request = addUserId
			? this.profile$.pipe(
					map(({ id_user }) => path.replace("/me/", `/${id_user}/`))
			  )
			: of(path);

		return request.pipe(
			map(thePath => `${environment.biAPIUrl}/${thePath}`),
			tap(URL => console.log("URL: ", URL))
		);
	}
	private GETBI(URL: string): Observable<any> {
		const OPTIONS = this.httpOptions;
		return this.HTTP.get<any>(URL, OPTIONS);
	}
	private mainGET(path: string, name?: string, addUserId = true) {
		const OPTIONS = this.httpOptions;

		return this.endpointURL(path, addUserId).pipe(
			switchMap(URL => this.GETBI(URL)),
			first(),
			tap(result => {
				console.groupCollapsed(
					`%cBI GET ${name?.toUpperCase() || path}`,
					this.css
				);
				console.log(`${path}`);
				console.log(result);
				console.groupEnd();
			})
		);
	}
	private mainPOST(path: string, data: any, addUserId = true) {
		//	const URL = `${environment.biAPIUrl}/${path}`;
		const OPTIONS = this.httpOptions;

		return this.encryptAccountData(data).pipe(
			switchMap(CRYPTEDDATA =>
				this.endpointURL(path, addUserId).pipe(
					map(URL => ({ URL, CRYPTEDDATA }))
				)
			),
			switchMap(({ URL, CRYPTEDDATA }) =>
				this.HTTP.post<any>(URL, CRYPTEDDATA, OPTIONS)
			),
			first(),
			tap(result => console.log(`%cBI POST ${path}`, this.css, result))
		);
	}

	private mainPUT(path: string, data: any, addUserId = true) {
		const URL = `${environment.biAPIUrl}/${path}`;
		const OPTIONS = this.httpOptions;

		return this.encryptAccountData(data).pipe(
			switchMap(CRYPTEDDATA =>
				this.endpointURL(path, addUserId).pipe(
					map(URL => ({ URL, CRYPTEDDATA }))
				)
			),
			switchMap(({ URL, CRYPTEDDATA }) =>
				this.HTTP.put<any>(URL, CRYPTEDDATA, OPTIONS)
			),
			first(),
			tap(result => console.log(`%cBI PUT ${path}`, this.css, result))
		);
	}

	private encryptAccountData(accountData: any) {
		const cryptographer = new Jose.WebCryptographer();
		const public_rsa_key = Jose.Utils.importRsaPublicKey(
			environment.biJWK,
			"RSA-OAEP"
		);
		const encrypter = new Jose.JoseJWE.Encrypter(cryptographer, public_rsa_key);
		encrypter.addHeader("kid", environment.biJWK.kid);

		const cryptedDatas = Object.keys(accountData).reduce((acc, k) => {
			acc[k] =
				k === "login" || k === "password"
					? encrypter.encrypt(accountData[k] || "")
					: (acc[k] = of(accountData[k]));
			return acc;
		}, {});
		return forkJoin(cryptedDatas);
	}

	// TERMS
	public getTerms() {
		return this.mainGET(`terms`, `terms`, false).pipe(
			tap(({ terms: { id } }) => (this._termsConsent = id === 4))
		);
	}
	private _termsConsent: boolean;
	public get termsConsent() {
		return this._termsConsent;
	}

	public consent() {
		return this.mainPOST(`users/me/terms`, { id_terms: 4 }, false).pipe(
			tap(({ terms: { id } }) => (this._termsConsent = id === 4)),
			tap(consent => console.log("%cconsent : ", this.css, consent))
		);
	}

	// BANKS
	private readonly _allBanks: BehaviorSubject<Bank[]> = new BehaviorSubject<
		Bank[]
	>(null);
	public get allBanks() {
		return this._allBanks.getValue();
	}
	public allBank$: Observable<Bank[]> = this._allBanks.asObservable().pipe(
		switchMap(banks =>
			banks
				? of(banks)
				: this.getBanks().pipe(
						map(({ connectors }) => connectors),
						tap(banks => this._allBanks.next(banks))
				  )
		)
	);

	public getBanks() {
		return this.mainGET(`connectors?expand=fields`, "banks").pipe(share());
	}

	// ACCOUNTS

	public listAccountsByAccountData(accountData: any) {
		// used in account service !
		return this.mainPOST(
			`users/me/connections?expand=all_accounts[investments],bank`,
			accountData
		).pipe(shareReplay());
	}

	public listAccountsByConnectionId(connectionId: string | number) {
		console.log("%clistAccountsByConnectionId: ", this.css, connectionId);
		return this.mainGET(
			`users/me/connections/${connectionId}?expand=all_accounts[investments],bank`,
			`Account list by connection id : ${connectionId}`
		).pipe(shareReplay());
	}

	public accountsByUser() {
		console.log("%caccountsByUser: ", this.css);
		this.initBI();
		return this.profile$.pipe(
			switchMap(({ id_user }) =>
				this.mainGET(
					`users/${id_user}/accounts`,
					`ALL accounts by user id : ${id_user}`
				)
			),
			shareReplay()
		);
	}

	public getAccount(
		connectionId: string | number,
		contractBIId: string | number
	) {
		return this.mainGET(
			`users/me/connections/${connectionId}/accounts/${contractBIId}`,
			`account by contract biid : ${contractBIId}`
		).pipe(
			tap(account =>
				console.log(`%cBI account ${account.name} => `, this.css, account)
			)
		);
	}

	public toggleAccount({ disabled, id, id_connection }: BiAccount) {
		return this.mainPUT(
			`users/me/connections/${id_connection}/accounts/${id}?all`,
			{ disabled: !disabled }
		);
	}

	// public deleteAccount(connectionId: any, accountId: any) {
	// 	return this.HTTP
	// 		.delete<any>(
	// 			`${environment.biAPIUrl}/users/me/connections/${connectionId}/accounts/${accountId}`,
	// 			this.httpOptions
	// 		)
	// 		.pipe(tap(() => console.log("%cBI deleteAccount", this.css)));
	// }
	// public disableAccount(connectionId: any, accountId: any) {
	// 	const accountData = { disabled: true };
	// 	return this.HTTP
	// 		.put<any>(
	// 			`${environment.biAPIUrl}/users/me/connections/${connectionId}/accounts/${accountId}?all`,
	// 			accountData,
	// 			this.httpOptions
	// 		)
	// 		.pipe(
	// 			first(),
	// 			tap(() => console.log(`%cBI disable Account ${accountId}`, this.css))
	// 		);
	// }
	// public enableAccount(connectionId: any, accountId: any) {
	// 	const accountData = { disabled: false };
	// 	return this.HTTP
	// 		.put<any>(
	// 			`${environment.biAPIUrl}/users/me/connections/${connectionId}/accounts/${accountId}?all`,
	// 			accountData,
	// 			this.httpOptions
	// 		)
	// 		.pipe(
	// 			first(),
	// 			tap(() => console.log(`%cBI enable Account ${accountId}`, this.css))
	// 		);
	// }

	// CONNECTIONS
	public listConnections() {
		return this.mainGET(
			`users/me/connections?expand=bank`,
			`ALL connections list`
		).pipe(
			map(({ connections }) => connections),
			shareReplay()
		);
	}

	public activateConnection(connectionId: number) {
		return this.mainPOST(`users/me/connections/${connectionId}`, {
			active: true
		}).pipe(
			tap(() => this.refresh()),
			tap(() => console.log("%cBI activateConnection", this.css))
		);
	}

	public changeConnectionAccount(
		connectionId: number,
		login: string,
		password: string,
		accountData: any
	) {
		let data = { login, password };

		for (let [k, v] of Object.entries(accountData)) {
			data[k] = accountData[k];
		}

		return this.mainPOST(`users/me/connections/${connectionId}`, data);
	}

	// public changeConnectionId(connectionId: number, login: string) {
	// 	return this.mainPOST(`users/me/connections/${connectionId}`, {
	// 		login: login
	// 	}).pipe(tap(() => console.log("%cBI changeConnectionId", this.css)));
	// }

	// public changeConnectionPassword(connectionId: number, password: string) {
	// 	return this.mainPOST(`users/me/connections/${connectionId}`, {
	// 		password: password
	// 	}).pipe(tap(() => console.log("%cBI changeConnectionPassword", this.css)));
	// }

	public deleteConnection(connectionId: any) {
		return this.HTTP.delete<any>(
			`${environment.biAPIUrl}/users/me/connections/${connectionId}`,
			this.httpOptions
		).pipe(
			tap(() => this.refresh())
			// tap(() => console.log('%cBI deleteConnection', this.css))
		);
	}
}
