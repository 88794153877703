import { Injectable } from "@angular/core";
import { InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Apollo } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { distinctUntilChanged } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication/authentication.service";
import { BudgetinsightService } from "./budgetinsight/budgetinsight.service";
import { UserService } from "./user/user.service";

@Injectable({ providedIn: "root" })
export class ConnectionService {
	private css = "color:yellow";
	private BOOSTIsInit: boolean = false;
	private BIIsInit: boolean = false;
	constructor(
		private BI: BudgetinsightService,
		private AUTH: AuthenticationService,
		private USER: UserService,
		private httpLink: HttpLink,
		private apollo: Apollo
	) {
		this.AUTH.isAuthenticated$.pipe(distinctUntilChanged()).subscribe(auth => {
			if (!this.BOOSTIsInit) this.initBOOSTConnection();
			else if (auth) this.setBOOSTToken();
			else this.resetBOOSTConnection();
		});

		this.USER.profile$.pipe(distinctUntilChanged()).subscribe(profile => {
			const token = profile?.biToken;
			console.log("token: ", token);
			this.setBIToken(token);
			// if (!this.BIIsInit) this.initBIConnection();
			// else if (token) this.setBIToken(token);
			// else this.resetBIConnection();
		});
	}

	private setBOOSTToken() {
		return setContext((_, { headers }) => {
			const token = this.AUTH.currentUser;
			return {
				headers: {
					...headers,
					Authorization: token ? `Bearer ${token}` : ""
				}
			};
		});
	}

	private initBOOSTConnection() {
		const uri = environment.apiUrl;

		const authLink = this.setBOOSTToken();
		const error = onError(
			({ graphQLErrors, networkError, operation, forward }) => {
				if (graphQLErrors) {
					for (let err of graphQLErrors) {
						switch (err.message) {
							case "jwt expired":
								this.AUTH.logout();
							case "Not authorized":
								this.AUTH.logout();
							default:
						}
					}
				}
				if (networkError) {
				}
			}
		);

		const cache = new InMemoryCache();

		const http = this.httpLink.create({ uri });
		const link = error.concat(http);

		this.apollo.create({
			link: authLink.concat(link),
			cache: cache,
			defaultOptions: {
				watchQuery: {
					errorPolicy: "all"
				}
			}
		});
		this.BOOSTIsInit = true;
	}

	private resetBOOSTConnection() {
		this.apollo.client.resetStore();
	}

	private setBIToken(BIToken) {
		this.BI.token = BIToken;
	}
	private initBIConnection() {
		this.BI.initBI();
		this.BIIsInit = true;
	}
	private resetBIConnection() {
		this.BI.refresh();
	}
}
