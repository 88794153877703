<div id="headBg">
  <div class="dropdown-container">
  <div
    class="dropdown"
    [ngClass]="{ active: toggleDropDown }"
    (mouseover)="toggleDropDown = true"
    (mouseout)="toggleDropDown = false"
  >
    <ul

    >
      <li [routerLink]="['account']">
        <i class="material-icons-outlined">account_balance</i>
        <span i18n="@@menuEtablissements">Établissements</span>
      </li>
      <li [routerLink]="['profile']">
        <i class="material-icons-outlined">person</i>
        <span i18n="@@menuVotreProfil">Votre profil</span>
      </li>
      <li (click)="onLogout()">
        <i class="material-icons-outlined">power_settings_new</i>
        <span i18n="@@menuSeDeconnecter">Déconnexion</span>
      </li>
    </ul>
  </div></div>
<div class="head-content">
  <div
    id="main-logo"
    (click)="refreshHomePage()"
    (mouseover)="toggleLogo = true"
    (mouseout)="toggleLogo = false"
    [ngClass]="{ active: toggleLogo }"
  >
    <i class="material-icons-outlined">autorenew</i>
    <img src="{{ logoSrc }}" />
  </div>
<!--
  <div class="echelleRisk">
    <strong>Echelle de risque :</strong>
    <div>
      <span class="risk-badge bg-risk-1">1</span>
      <span class="risk-badge bg-risk-2">2</span>
      <span class="risk-badge bg-risk-3">3</span>
      <span class="risk-badge bg-risk-4">4</span>
      <span class="risk-badge bg-risk-5">5</span>
      <span class="risk-badge bg-risk-6">6</span>
      <span class="risk-badge bg-risk-7">7</span>
    </div>
  </div>
-->  <nav>
<div class="connectionIssues">
  <issue-icon *ngFor="let issue of connectionIssuesIcon$|async" [issue]="issue" (click)="openConnectionsIssuesModal()"></issue-icon>
</div>

    <a id="addContrat" [routerLink]="[{ outlets: { modal: ['add-account'] } }]">
      <i class="material-icons-outlined">add</i>
      <span>Ajouter un contrat</span>
    </a>

    <a
      (click)="toggleDropDown = !toggleDropDown"
      (mouseover)="toggleDropDown = true"
      (mouseout)="toggleDropDown = false"
      [ngClass]="{ 'active': toggleDropDown }"
    >
      <div class="user-info">
        <span class="material-icons">
          person_outline
          </span>
      </div>
    </a>
  </nav>

</div>

</div>
