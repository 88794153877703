import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { NotFoundComponent } from "./NotFoundComponent.page.component";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { BudgetinsightService } from "./services/budgetinsight/budgetinsight.service";
import { ConnectionService } from "./services/connection.service";
import { ContractService } from "./services/contract/contract.service";
import { DashboardService } from "./services/dashboard/dashboard.service";
import { UserService } from "./services/user/user.service";

@NgModule({
	declarations: [NotFoundComponent],
	exports: [NotFoundComponent],
	imports: [CommonModule, RouterModule],
	providers: [
		AuthGuard,
		AuthenticationService,
		ConnectionService,
		UserService,
		ContractService,
		BudgetinsightService,
		DashboardService
	]
})
export class CoreModule {}
