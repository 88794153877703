import { Injectable } from "@angular/core";
import { Apollo, QueryRef } from "apollo-angular";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { Contract } from "../../../app.interface";
import { getDashboard } from "../../graphql/queries/User";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable({ providedIn: "root" })
export class DashboardService {
	private css = "color:#2980b9;font-weight:bold";

	private readonly _reload: BehaviorSubject<boolean> = new BehaviorSubject(
		false
	);
	public readonly reload$: Observable<boolean> = this._reload.asObservable();

	private getDashboardQuery: QueryRef<any> = this.apollo.watchQuery<any>({
		query: getDashboard,
		fetchPolicy: "network-only"
		//	fetchPolicy: "cache-and-network"
	});

	public loadDashboard() {
		this._reload.next(true);
		return this.getDashboardQuery
			.result()
			.then(data => this._dashboardData.next(data));
	}
	public reLoadDashboard() {
		this._reload.next(true);
		return this.getDashboardQuery
			.refetch()
			.then(data => this._dashboardData.next(data));
	}

	private readonly _dashboardData: BehaviorSubject<any> = new BehaviorSubject(
		null
	);

	public readonly dashboardData$: Observable<any> = this._dashboardData
		.asObservable()
		.pipe(
			filter(dashboardData => !!dashboardData?.data?.me),
			//	tap(data => console.log(`%cDASHBOARD => `, this.css, data)),
			tap(({ loading }) => this._reload.next(loading))
			//	share()
		);

	public readonly contract$ = this.dashboardData$.pipe(
		map(({ data }) => data.me),
		filter(me => !!me),
		map(me => me.Contracts.filter(contract => !!contract)),
		map(contracts =>
			contracts?.filter(({ ContractLastUpdate }) => ContractLastUpdate?.type)
		)
		//	tap(d => console.log("%c > contracts ", this.css, d))
		//share()
	);

	public readonly otherContract$ = this.contract$.pipe(
		map((contracts: Contract[]) =>
			contracts.filter(
				({ ContractLastUpdate }) =>
					ContractLastUpdate.type === "perco" ||
					ContractLastUpdate.type === "article83" ||
					ContractLastUpdate.type === "madelin" ||
					ContractLastUpdate.type === "pee" ||
					ContractLastUpdate.type === "pea" ||
					ContractLastUpdate.type === "capitalisation" ||
					ContractLastUpdate.type === "savings" ||
					ContractLastUpdate.type === "market" ||
					ContractLastUpdate.type === "perp" ||
					ContractLastUpdate.type === "per" ||
					ContractLastUpdate.type === "unknown"
			)
		)
		//	tap(d => console.log("%c > otherContracts : ", this.css, d))
	);

	public readonly mainContract$ = this.contract$.pipe(
		map((contracts: Contract[]) =>
			contracts.filter(
				contract =>
					// contract.freeManagement &&
					contract.ContractLastUpdate.type === "lifeinsurance"
			)
		)
		//	tap(d => console.log("%c > mainContracts : ", this.css, d))
	);

	// public readonly notFreeManagementContract$ = this.contract$.pipe(
	// 	map((contracts: Contract[]) =>
	// 		contracts.filter(
	// 			contract =>
	// 				contract.ContractLastUpdate.type === "lifeinsurance" &&
	// 				!contract.freeManagement
	// 		)
	// 	),
	// 	tap(d => console.log("%c > notFreeManagementContract : ", this.css, d))
	// );

	constructor(private apollo: Apollo, private AUTH: AuthenticationService) {
		//	this.loadDashboard();

		this.reload$
			.pipe(
				filter(reload => reload),
				switchMap(() => this.dashboardData$),
				filter(dashboardData => !!dashboardData)
			)
			.subscribe(() => this._reload.next(false));

		this.AUTH.isAuthenticated$
			.pipe(
				tap(isAuthenticated => {
					if (!isAuthenticated) this.reset();
					else if (!this._dashboardData.getValue()) this.loadDashboard();
				})
			)
			.subscribe();
	}

	public reset() {
		this._dashboardData.next(null);
	}
}
