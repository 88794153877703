import { gql } from "apollo-angular";

export const getInstance = gql`
  query {
    getInstance {
      slug
      name
    }
  }
`;
