import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "src/app/core/services/account.service";
import { AuthenticationService } from "src/app/core/services/authentication/authentication.service";
import { ThemeService } from "src/app/core/services/theme.service";
import { DashboardService } from "../../../core/services/dashboard/dashboard.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	@Input() me: any;
	public toggleDropDown: boolean;
	public toggleLogo: boolean;
	public logoSrc: string = `./assets/images/logo/${this.THEME.active.logo_top}`;

	public connectionIssuesIcon$ = this.ACCOUNT.connectionIssuesIcon$;

	constructor(
		private DASHBOARD: DashboardService,
		public router: Router,
		private authenticationService: AuthenticationService,
		private THEME: ThemeService,
		private ACCOUNT: AccountService
	) {}

	ngOnInit() {}
	public refreshHomePage() {
		const isHomePage = this.router.url == "/";
		this.toggleLogo = false;
		if (isHomePage) this.DASHBOARD.reLoadDashboard();
		else this.router.navigate(["./"]);
	}
	public onLogout() {
		this.authenticationService.logout();
	}
	public openConnectionsIssuesModal() {
		this.ACCOUNT.showConnectionsIssues = true;
	}
}
