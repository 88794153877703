import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
	AccountService,
	ConnectionsWithIssue
} from "src/app/core/services/account.service";
import { getBIErrorMessage } from "src/app/core/utils/error-messages";

@Component({
	selector: "connections-Issues",
	templateUrl: "./connections-Issues.component.html",
	styleUrls: ["./connections-Issues.component.scss"]
})
export class ConnectionsIssuesComponent implements OnInit {
	public errorMessage = getBIErrorMessage;
	public connectionsWithIssue$: Observable<ConnectionsWithIssue[]> = this
		.ACCOUNT.connectionsWithIssue$;
	constructor(private ACCOUNT: AccountService) {}

	ngOnInit() {}
}
