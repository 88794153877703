import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Bank, Connection } from "src/app/app.interface";

@Component({
	selector: "bi-fields-form",
	templateUrl: "./bi-fields-form.component.html",
	styleUrls: ["./bi-fields-form.component.scss"]
})
export class BiFieldsFormComponent implements OnInit {
	@Input() connection: Connection | Bank;

	public form: FormGroup;
	public loading: boolean;
	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		const controllers = this.connection.fields?.reduce(
			(acc, { name, required }) => ({
				...acc,
				[name]: [
					null,
					{
						updateOn: "blur",
						validators: [Validators.required]
					}
				]
			}),
			{}
		);

		if (controllers) this.form = this.fb.group(controllers);

		console.log("connection => " + this.connection);
	}

	public submitForm() {
		if (!this.form.valid) return;
		this.loading = true;
		console.log(this.form.getRawValue());
	}
}

/*******************************
 *
 {
    "name": "password",
    "label": "Code secret",
    "regex": null,
    "type": "password",
    "required": true,
    "auth_mechanisms": [
        "credentials"
    ],
    "connector_sources": [
        "directaccess"
    ]
}
 */
