import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import {
	delay,
	distinctUntilChanged,
	filter,
	first,
	map,
	share,
	switchMap,
	take,
	takeWhile,
	tap
} from "rxjs/operators";
import { ContractService } from "src/app/core/services/contract/contract.service";
import { RefDataService } from "src/app/core/services/refdata/ref-data.service";
import { BiAccount, Contract, SelectOption } from "../../../app.interface";
import {
	AccountService,
	PROJECT_OPTIONS
} from "../../../core/services/account.service";
import { BudgetinsightService } from "../../../core/services/budgetinsight/budgetinsight.service";
import { objectDiff, toInt } from "../../../core/services/utils.service";
export interface SubmitContractPayload {
	id: number;
	connectionId: number;
	bankId: number;
	contractOPCVMId: number;
	companyName: string;
	contractName: string;
	freeManagement: boolean;
	projects: string[];
	produit: string;
}
@Component({
	selector: "account-form",
	templateUrl: "./account-form.component.html",
	styleUrls: ["./account-form.component.scss"]
})
export class AccountFormComponent implements OnInit {
	private css = "color:#8bc24b;font-weight:bold";
	@Input() connectionId: string | number;
	@Input() contractBIId: string | number;
	@Input() account$: Observable<BiAccount>;
	@Input() contract: Contract;

	public feedback: "success" | "danger";
	public mainLoading: boolean = true;
	public loading: boolean;
	public isSalarialAccount: boolean = false;
	private alive = true;
	private readonly _accountData: BehaviorSubject<SubmitContractPayload> =
		new BehaviorSubject<SubmitContractPayload>(null);
	public accountData$: Observable<SubmitContractPayload> =
		this._accountData.asObservable();

	public get accountData() {
		return this._accountData?.getValue();
	}
	public disabledAccount: boolean = false;
	public account: BiAccount;
	public projectOptions: SelectOption[] = PROJECT_OPTIONS;
	public produitOption$: Observable<SelectOption[]> = this.accountData$.pipe(
		filter(accountData => !!accountData),
		switchMap(accountData => this.REF.getRefContracts(accountData.bankId)),
		map(({ data }) =>
			data?.RefContractsByBankId.map(({ value, label }) => ({
				value,
				label
			}))
		)
	);

	public form: FormGroup = this.fb.group({
		contractName: [null, { updateOn: "blur", validators: [] }],
		companyName: [null, { updateOn: "blur", validators: [] }],
		projects: [null, { updateOn: "blur", validators: [] }],
		produit: [null, { updateOn: "blur", validators: [] }],
		freeManagement: [false, { updateOn: "blur", validators: [] }]
	});

	constructor(
		private ACCOUNT: AccountService,
		private CONTRACT: ContractService,
		private BI: BudgetinsightService,
		private fb: FormBuilder,
		private REF: RefDataService
	) {}

	ngOnInit() {
		console.log("-----------");
		//	this.BI.accountsByUser().subscribe();
		if (this.contract) {
			console.log("==> this.contract: ", this.contract);
			const { connectionId, contractBIId } = this.contract;
			this.connectionId = connectionId;
			this.contractBIId = contractBIId;
		}
		if (this.account$) this.account$.subscribe(account => this.init(account));

		if (this.connectionId && this.contractBIId) this.init(null);

		this.form.valueChanges
			.pipe(
				distinctUntilChanged(),
				takeWhile(() => this.alive)
			)
			.subscribe(() => this.checkAndPost());
	}

	// private differences(): boolean {
	// 	const formData = this.form.value;
	// 	const differences = Object.keys(formData).filter(
	// 		key => formData[key] !== this.accountData[key]
	// 	);
	// 	return differences.length === 0;
	// }

	private checkAndPost() {
		const values = this.form.value;
		const differencies = objectDiff(values, this.accountData).length > 0;

		if (!this.accountData || !this.form || !differencies) return;

		this.loading = true;
		const payLoad = { ...this.accountData, ...values };

		this.CONTRACT.saveContract(payLoad)
			.pipe(
				first(),
				delay(200) // laisse tourner le loader en cas de reponse rapide
			)
			.subscribe({
				next: () => {},
				error: err => {
					console.error("something wrong occurred: " + err);
					this.feedback = "danger";
					setTimeout(() => (this.feedback = null), 3000);
				},
				complete: () => {
					this._accountData.next(payLoad);
					this.loading = false;
					this.feedback = "success";
					setTimeout(() => (this.feedback = null), 3000);
					console.log("%c CHECK AND POST: ", this.css, payLoad);
				}
			});
	}

	private init(account) {
		console.log("====> account: ", account);
		if (!account && !(this.connectionId && this.contractBIId)) return;

		const BIId =
			account?.id || this.contract?.Bank?.id || toInt(this.contractBIId);

		const BIAccount = !!account
			? of(account)
			: this.ACCOUNT.getAccount(this.connectionId, this.contractBIId);

		const boostAccount = !!this.contract?.Bank?.id
			? of(this.contract)
			: this.CONTRACT.getContractByBIId(BIId).pipe(
					map(({ data }) => data?.ContractByBIId)
			  );

		// console.groupCollapsed("%cACCOUNT FORM INIT", this.css);
		// console.log("%c connectionId : ", this.css, this.connectionId);
		// console.log("%c contractBIId : ", this.css, this.contractBIId);
		// console.log("%c account : ", this.css, account);
		// console.log("%c contract : ", this.css, this.contract);

		// console.log("BIId: ", BIId);
		// console.log("BIAccount: ", BIAccount);
		// console.log("boostAccount: ", boostAccount);
		// console.groupEnd();

		forkJoin({
			bi: BIAccount.pipe(
				take(1),
				tap(BiAccount => {
					console.log("BiAccount: ", BiAccount);
					this.account = BiAccount;
					this.disabledAccount = BiAccount.disabled ? true : false;
				}),
				takeWhile(() => this.alive)
			),
			boost: boostAccount.pipe(
				take(1),
				takeWhile(() => this.alive)
			)
		})
			.pipe(
				filter(({ bi, boost }) => !!bi && !!boost),
				share(),
				tap(({ bi, boost }) => {
					console.log("%c ===> ", this.css, { bi, boost });
				}),
				tap(
					({ bi, boost: { type } }) =>
						(this.isSalarialAccount = type === "perco" || type === "pee")
				),
				map(({ bi, boost }) => ({
					id: toInt(bi?.id),
					connectionId: toInt(bi.id_connection),
					companyName: boost.companyName || bi.company_name,
					contractName: boost.contractName || bi.contractName,
					contractOPCVMId: boost.contractOPCVMId,
					freeManagement: boost.freeManagement ? true : false,
					projects: boost.projects,
					bankId: toInt(boost?.Bank?.id),
					produit: null
				})),
				tap(account => this._accountData.next(account)),
				tap(() => (this.mainLoading = false)),
				tap(({ contractName, companyName, projects, freeManagement }) =>
					this.form.patchValue({
						contractName,
						companyName,
						projects,
						freeManagement
					})
				),
				takeWhile(() => this.alive)
			)
			.subscribe();
	}

	// public submitForm(formdata: any, accountData: any) {
	// 	console.groupCollapsed("%cACCOUNT FORM SUBMIT", this.css);
	// 	console.log("accountData: ", accountData);
	// 	console.log("formdata: ", formdata);
	// 	const payLoad = { ...accountData, ...formdata };
	// 	console.log("payLoad: ", payLoad);

	// 	console.groupEnd();
	// 	//	if (JSON.stringify(payLoad) === JSON.stringify(accountData)) return;

	// 	return this.CONTRACT.saveContract(payLoad)
	// 		.pipe(first())
	// 		.subscribe({
	// 			next: ({ data, loading }: any) => {
	// 				this.loading = loading;
	// 			},
	// 			error: err => console.error("something wrong occurred: " + err),
	// 			complete: () => console.log("done")
	// 		});
	// }
	ngOnDestroy() {
		this.alive = false;
	}

	public toggleActivation() {
		this.BI.toggleAccount(this.account).subscribe(accountUpdated => {
			this.init(accountUpdated);
			this.ACCOUNT.updateAccount = accountUpdated;
		});
	}
}
