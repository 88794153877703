import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { SelectOption } from "../../app.interface";
import { errorsMessages } from "../../config";

@Component({
	selector: "boost-select",
	template: `
		<div class="boost-select_form-group" [formGroup]="form">
			<label>{{ label }}</label>

			<ng-select
				[labelForId]="label"
				[items]="items"
				bindLabel="label"
				bindValue="value"
				[placeholder]="label"
				[clearable]="clearable"
				[searchable]="false"
				[formControlName]="controlName"
				[multiple]="multiple"
			>
			</ng-select>

			<p
				*ngIf="
					form.get(controlName)?.invalid &&
					(form.get(controlName)?.touched || form.get(controlName)?.dirty)
				"
			>
				<span>{{ errorMessage() }}</span>
			</p>
		</div>
	`
})
export class BoostSelectComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() controlName: string;
	@Input() label: string;
	@Input() clearable: boolean = true;
	@Input() items: SelectOption[];
	@Input() multiple: boolean;

	private control: AbstractControl;
	public errorsMessages: any;
	public defaultMessage = errorsMessages["default"];

	constructor() {}
	ngOnInit() {
		this.errorsMessages = errorsMessages[this.controlName];
		this.control = this.form.controls[this.controlName];
	}

	public errorMessage = (): string => {
		if (!this.errorsMessages) return this.defaultMessage;
		const controlErrors = this.control?.errors;
		if (!controlErrors) return null;
		const errorName = Object.keys(controlErrors)[0].toLowerCase();
		if (!errorName) return this.defaultMessage;
		return this.errorsMessages[errorName] || this.defaultMessage;
	};
}
