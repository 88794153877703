import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { BoostComponentsModule } from "../components/boost-components.module";
import { AccountFormComponent } from "./account-form/account-form.component";
import { AddConnectionFormComponent } from "./add-connection-form/add-connection-form.component";
import { BiFieldsFormComponent } from "./bi-fields-form/bi-fields-form.component";
import { BoostCheckboxComponent } from "./boost-checkbox.component";
import { BoostInputComponent } from "./boost-input.component";
import { BoostSelectComponent } from "./boost-select.componen";
import { BoostSubmitButtonComponent } from "./boost-submit-button.component";
import { ConnectionsIssuesComponent } from "./connections-Issues/connections-Issues.component";
import { EditConnectionFormComponent } from "./edit-connection-form/edit-connection-form.component";
import { ProfilDetailsFormComponent } from "./profil-details-form/profil-details-form.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		BoostComponentsModule
	],
	declarations: [
		AddConnectionFormComponent,
		EditConnectionFormComponent,
		BoostInputComponent,
		BoostSubmitButtonComponent,
		ProfilDetailsFormComponent,
		AccountFormComponent,
		BoostSelectComponent,
		BiFieldsFormComponent,
		BoostCheckboxComponent,
		ConnectionsIssuesComponent
	],

	exports: [
		AddConnectionFormComponent,
		EditConnectionFormComponent,
		BoostInputComponent,
		BoostSubmitButtonComponent,
		ProfilDetailsFormComponent,
		AccountFormComponent,
		BoostSelectComponent,
		BiFieldsFormComponent,
		BoostCheckboxComponent,
		ConnectionsIssuesComponent
	],
	providers: []
})
export class BoostFormsModule {}
