import { Component, OnInit } from "@angular/core";

@Component({
	selector: "NotFound",
	template: `
		<div class="page">
			<div></div>
			<div>
				<h1>404</h1>
				<p>La page que vous recherchez n'existe pas.</p>
				<a href="" [routerLink]="['/', 'dashboard']" class="btn primary"
					>Retour au dashboard</a
				>
			</div>
		</div>
	`,
	styleUrls: ["./NotFoundComponent.page.component.scss"]
})
export class NotFoundComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
