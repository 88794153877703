import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	constructor(
		private apollo: Apollo,
		//private biService: BudgetinsightService,
		public router: Router
	) {}

	/// CURRENT
	private readonly _currentUser: BehaviorSubject<string> = new BehaviorSubject(
		localStorage.getItem("currentUser")
	);

	public readonly currentUser$: Observable<string> =
		this._currentUser.asObservable();

	public set currentUser(data: string) {
		if (!!data) {
			localStorage.setItem("currentUser", data);
			this._currentUser.next(data);
		} else {
			localStorage.removeItem("currentUser");
			this._currentUser.next(null);
		}
	}
	public get currentUser() {
		return this._currentUser.getValue();
	}

	public readonly isAuthenticated$: Observable<boolean> =
		this.currentUser$.pipe(map(token => !!token));

	public get isAuthenticated() {
		return !!this.currentUser;
	}

	// AUTHENTICATION
	public login(email: string, password: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation login($email: String!, $password: String!) {
						login(email: $email, password: $password) {
							token
						}
					}
				`,
				variables: {
					email: email,
					password: password
				}
			})
			.pipe(
				map((result: any) => {
					// console.log('login params : ', email, password);
					// console.log('login result : ', result);
					console.log("%cLOGIN ", "color:green", this.isAuthenticated);

					// login successful if there's a jwt token in the response
					if (result?.data?.login?.token) {
						// store user details and jwt token in local storage to keep user logged in between page refreshes

						this.currentUser = result.data.login.token;
					} else {
						this.currentUser = null;
						//localStorage.removeItem("currentUser");
					}
					return;
				})
			);
	}
	public logout() {
		if (!this.isAuthenticated) return;
		// remove user from local storage to log user out
		//	this.biService.close();
		this.apollo.client.resetStore();
		console.log("%cLOGOUT", "color:red");
		// localStorage.removeItem("currentUser");
		this.currentUser = null;
		this.router.navigate(["/auth/login"]);
	}
	public reset() {
		this.currentUser = null;
		//	localStorage.removeItem("currentUser");
	}

	// INSCRIPTION
	public signup(nickname: string, email: string, password: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation signup(
						$nickname: String!
						$email: String!
						$password: String!
					) {
						signup(nickname: $nickname, email: $email, password: $password) {
							token
						}
					}
				`,
				variables: {
					nickname: nickname,
					email: email,
					password: password
				}
			})
			.pipe(
				map((result: any) => {
					// login successful if there's a jwt token in the response
					if (result.data && result.data.signup.token) {
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						localStorage.setItem("currentUser", result.data.signup.token);
					}

					return result;
				})
			);
	}
	public confirmSignup(confirmSignUpToken: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation confirmSignup($confirmSignUpToken: String!) {
						confirmSignup(confirmSignUpToken: $confirmSignUpToken)
					}
				`,
				variables: {
					confirmSignUpToken: confirmSignUpToken
				}
			})
			.pipe(
				map(result => {
					return result;
				})
			);
	}

	// EDITION
	public forgotPassword(email: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation forgotPassword($email: String!) {
						forgotPassword(email: $email)
					}
				`,
				variables: {
					email: email
				}
			})
			.pipe(
				map(result => {
					return result;
				})
			);
	}

	public changePassword(password: string, resetPasswordToken: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation changePassword(
						$password: String!
						$resetPasswordToken: String!
					) {
						changePassword(
							password: $password
							resetPasswordToken: $resetPasswordToken
						)
					}
				`,
				variables: {
					password: password,
					resetPasswordToken: resetPasswordToken
				}
			})
			.pipe(
				map(result => {
					return result;
				})
			);
	}
}
