export const environment = {
  production: true,
  envName: 'mvp',
  apiUrl: 'https://gql-staging.k8s.boost-epargne.com/',
  biAPIUrl: 'https://boostepargne-staging.biapi.pro/2.0',
  biClientId: 83792912,
  biClientSecret: 'r4VtiyXNs7mVIMGAtsuVnNht5sze5o95',
  biJWK: {
    'e': 'AQAB',
    'kid': 'q6fdlNUYXZVtVqCxhLW_M_K7UkCHn00tTX-MRdmD1Ls',
    'kty': 'RSA',
    'n': 'pLtiDVI7SR5ejaDSlcRIluj-yp4S7WIcKqk6Q0nmY2POGQfRT-uOjGdZ8vGb9VmjrNzGunuGcB4cBM0cwPYEnY7qsGZg2wTDRvUrdSRmA9CoIkgWBaUob_Fpn759gxKJx_kRcFEvEy-6LuOB72jYwF0ObzbNebmlhox4bL0kUvBCRFMlBkrzZ3hTmQc55DHG0ZMcJte1BTKZ2DuxSFFHiWu9o3ujdAmR8XRphjIYiZcBNG42I6ng5Tb-nyJD8X4PsTKS7-umwq4CzvxL3MiOTPwhuYW-61y1QiytSx0DyPdmOMbSaqqe4sUWlcUhAOHVwvcT2ZWMEs32_vdo5yuzmw'
  }
};
