import { Component, OnInit } from "@angular/core";
import dayjs from "dayjs";
@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
	public year = dayjs().format("YYYY");
	constructor() {}

	ngOnInit() {}
}
