import { Component, OnInit } from "@angular/core";
import browserUpdate from "browser-update";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "./app.interface";
import { config } from "./config";
import { AccountService } from "./core/services/account.service";
import { AuthenticationService } from "./core/services/authentication/authentication.service";
import { ContractService } from "./core/services/contract/contract.service";
import { NavigationService } from "./core/services/navigation.service";
import { ThemeService } from "./core/services/theme.service";
import { UserService } from "./core/services/user/user.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
	public me: any;

	public isAuthenticated$: Observable<boolean> = this.AUTH.isAuthenticated$;
	public loading: boolean;
	public openModal: Observable<boolean> = this.NAV.openModal$;
	public dontmindfailedconnections = !config.global.popin.failedConnection;
	public showConnectionsIssue$ = this.ACCOUNT.showConnectionsIssue$;
	public showContractSetting$ = this.CONTRACT.showContractSetting$;
	private readonly _failedConnection: BehaviorSubject<
		any[]
	> = new BehaviorSubject<any[]>([]);
	public readonly failedConnection$: Observable<
		any[]
	> = this._failedConnection.asObservable();

	constructor(
		private USER: UserService,
		private THEME: ThemeService,
		private ACCOUNT: AccountService,
		private CONTRACT: ContractService,
		private NAV: NavigationService,
		private AUTH: AuthenticationService
	) {}

	ngOnInit() {
		// browser update ribbon
		browserUpdate({
			required: {
				e: 79,
				// i: 10,
				f: -3,
				o: -3,
				s: 10.1,
				c: "64.0.3282.16817",
				samsung: 7.0,
				vivaldi: 1.2
			},
			insecure: true,
			// test: true,
			style: "bottom"
		});

		//this.THEME.setActiveTheme("axa");
		this.THEME.setActiveTheme("default");
		//	this.THEME.setActiveTheme("bnp");

		// console.log(environment.envName, this.THEME.getActiveTheme());
		// console.log(environment.envName);
	}

	public profile$: Observable<User> = this.USER.profile$;
	// Notify MAIN service when modal is open/closed
	public deactivateModal(event) {
		this.NAV.openModal = false;
	}
	public activateModal(event) {
		this.NAV.openModal = true;
	}
	public closeModal() {
		this.ACCOUNT.showConnectionsIssues = false;
		this.CONTRACT.showContractSettings = null;
	}
}
