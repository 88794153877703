import { gql } from "apollo-angular";

export const getContractValues = gql`
	query Contract($id: ID!, $limit: Int!) {
		Contract: Contract(id: $id) {
			id
			ContractValues(limit: $limit) {
				id
				risk
				arbitrage
				valuation
				timestamp
				tri {
					tauxAnnuel
				}
			}
		}
	}
`;

export const getOtherContractValues = gql`
	query Contract($id: ID!, $limit: Int!) {
		Contract: Contract(id: $id) {
			id
			ContractValues(limit: $limit) {
				timestamp
				risk
				tri {
					tauxAnnuel
				}
			}
		}
	}
`;
//((filter: { risk: { gt: 3 } }) )
//(risk: { gt: 3 })
//timestamp: { gt: 1549044285 }
export const getContract = gql`
	query Contract($id: ID!) {
		Contract: Contract(id: $id) {
			id
			contractOPCVMId
			connectionId
			contractBIId
			contractName
			refContractName
			refContractId
			type
			freeManagement

			Bank {
				id
				name
				slug
			}
			User {
				id
				nickname
			}
			ContractFirstUpdate {
				last_update
			}
			ContractLastUpdate {
				id
				valuation
				type
				idCompanyPromoter
				tri {
					tauxAnnuel
				}
				tri_formatted
				earnings_formatted
				last_update
				valuation_formatted
				risk
				investments {
					isin
					perf_formatted
					portfolio_share_formatted
					portfolio_share
					categoryOpcvm
					risk
					refLabel
					label
					valuation_date
					perf
					isComplete
				}
			}

			cashAvailability {
				Y1
				Y2
				Y3
				Y4
				Y5
			}
		}
	}
`;
export const getContract_old = gql`
	query Contract($id: ID!) {
		Contract: Contract(id: $id) {
			id
			contractOPCVMId
			connectionId
			contractBIId
			contractName
			refContractName
			refContractId
			followers
			type
			freeManagement
			FollowSameKind {
				id
				refContractName
				User {
					id
					nickname
				}
				ContractLastUpdate {
					id
					risk
					tri_formatted
					tri {
						tauxAnnuel
					}
					position
					totalSameKind
				}
			}
			Bank {
				id
				name
				slug
			}
			User {
				id
				nickname
			}
			ContractFirstUpdate {
				id
				last_update
				valuation
				valuation_formatted
			}
			ContractLastUpdate {
				id
				insurerName
				idCompanyPromoter
				type
				last_update
				valuation_formatted
				valuation
				earnings_formatted
				euroFundShare
				euroFundShare_formatted
				ucShare
				ucShare_formatted
				risk
				investments {
					valuation_date
					isin
					label
					refLabel
					categoryOpcvm
					portfolio_share
					portfolio_share_formatted
					risk
					perf
					perf_formatted
					isComplete
				}
				tri_formatted
				tri {
					tauxAnnuel
				}
				position
				totalSameKind
			}
			ContractValues {
				id
				risk
				arbitrage
				valuation
				timestamp
				tri {
					tauxAnnuel
				}
			}
			cashAvailability {
				Y1
				Y2
				Y3
				Y4
				Y5
			}
		}
	}
`;
/*
  refFunds {
        id
        name
        isin
        categoryOpcvm
        categoryOpcvmLibelle
        risk
        owned
      } */
export const getOtherContract = gql`
	query Contract($id: ID!) {
		Contract: Contract(id: $id) {
			id
			contractName
			refContractName
			refContractId
			followers
			FollowSameKind {
				id
			}
			Bank {
				id
				name
				slug
			}
			User {
				id
				nickname
			}
			ContractLastUpdate {
				id
				insurerName
				earnings_formatted
				euroFundShare
				euroFundShare_formatted
				ucShare
				ucShare_formatted
				risk
				investments {
					valuation_date
					isin
					refLabel
					categoryOpcvm
					portfolio_share
					portfolio_share_formatted
					risk
					perf
					perf_formatted
				}
				tri_formatted
				tri {
					tauxAnnuel
				}
				position
				totalSameKind
			}
			ContractValues {
				id
				risk
				arbitrage
				timestamp
				tri {
					tauxAnnuel
				}
			}
			refFunds {
				id
				name
				isin
				categoryOpcvm
				categoryOpcvmLibelle
				risk
				volat1Y
				varP1Y
				volat3Y
				varP3Y
				volat5Y
				varP5Y
				owned
			}
		}
	}
`;

export const getLastContracts = gql`
	query Contracts($limit: Int!) {
		Contracts: Contract(limit: $limit) {
			id

			User {
				nickname
			}
			ContractLastUpdate {
				risk
				tri_formatted
				position
				totalSameKind
				tri {
					tauxAnnuel
				}
			}
			ContractValues {
				timestamp
				tri {
					tauxAnnuel
				}
			}
		}
	}
`;
export const getContractsByRef = gql`
	query ContractsByRef($refContractId: Int!) {
		ContractsByRef: Contract(refContractId: $refContractId) {
			id
			contractName
			refContractName
			ContractLastUpdate {
				id
				tri {
					tauxAnnuel
				}
			}
		}
	}
`;

export const getContractByBIId = gql`
	query ContractByBIId($BIId: Int!) {
		ContractByBIId: ContractByBIId(BIId: $BIId) {
			id
			contractBIId
			connectionId
			refContractId
			type
			contractName
			refContractName
			name
			contractOPCVMId
			projects
			freeManagement
			User {
				is_admin
			}
			Bank {
				id
				name
				account_types
			}
		}
	}
`;

export const searchContracts = gql`
	query searchContracts($filter: String!) {
		searchContracts(filter: $filter) {
			id
			risk
			earnings_formatted
			tri {
				tauxAnnuel
			}
			tri_formatted
			Contract {
				id
				followers
				Bank {
					id
					name
					slug
				}
				User {
					id
					nickname
				}
			}
		}
	}
`;

export const getTierContract = gql`
	query TierContract($id: ID!) {
		Contract: Contract(id: $id) {
			id
			refContractName
			refContractId
			followers
			FollowSameKind {
				id
			}
			Bank {
				id
				name
				slug
			}
			User {
				id
				nickname
			}
			ContractFirstUpdate {
				id
				last_update
				valuation
				valuation_formatted
			}
			ContractLastUpdate {
				id
				insurerName
				last_update
				earnings_formatted
				euroFundShare
				euroFundShare_formatted
				ucShare
				ucShare_formatted
				risk
				investments {
					valuation_date
					isin
					refLabel
					categoryOpcvm
					portfolio_share
					portfolio_share_formatted
					risk
					perf
					perf_formatted
				}
				tri_formatted
				tri {
					tauxAnnuel
				}
				position
				totalSameKind
			}
			ContractValues {
				id
				risk
				arbitrage
				timestamp
				tri {
					tauxAnnuel
				}
			}
		}
	}
`;

export const bestContracts = gql`
	query bestContracts($refContractId: Int, $risk: Int, $limit: Int!) {
		bestContracts(refContractId: $refContractId, risk: $risk, limit: $limit) {
			id
			Contract {
				ContractLastUpdate {
					position
				}
				id
				User {
					id
					nickname
				}
				followers
			}
			risk
			tri_formatted
			tri {
				tauxAnnuel
			}
		}
	}
`;

export const worstContracts = gql`
	query worstContracts($refContractId: Int, $risk: Int, $limit: Int!) {
		worstContracts(refContractId: $refContractId, risk: $risk, limit: $limit) {
			id
			Contract {
				ContractLastUpdate {
					position
				}
				id
				refContractName
				User {
					id
					nickname
				}
				followers
			}
			risk
			tri_formatted
			tri {
				tauxAnnuel
			}
		}
	}
`;

export const compareContracts = gql`
	query compareContracts($compareContractsIds: [String]) {
		compareContracts(compareContractsIds: $compareContractsIds) {
			investments
		}
	}
`;

export const followContract = gql`
	mutation followContract($contractId: String!) {
		followContract(contractId: $contractId)
	}
`;

export const unFollowContract = gql`
	mutation unFollowContract($contractId: String!) {
		unFollowContract(contractId: $contractId)
	}
`;

export const getAvailablePromoters = gql`
	query {
		availablePromoters {
			id
			name
		}
	}
`;

export const getAvailableInsurers = gql`
	query {
		availableInsurers {
			id
			name
		}
	}
`;
