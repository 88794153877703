<ng-container *ngIf="connectionsWithIssue$ | async as connectionsList; else loading">




  <article *ngFor="let connection of connectionsList">
    <header>
      <h5>{{ connection.bank.name }} </h5>
      <div>
        <issue-icon [color]="connection.color" [icon]="connection.icon"></issue-icon>
      </div>
    </header>
    <main>
      <p class="errorMessage" *ngIf="!connection.active || connection.error">
        {{connection.message}}
      </p>
      <bi-fields-form [connection]="connection" *ngIf="connection?.fields"></bi-fields-form>
    </main>
  </article>




</ng-container>
<ng-template #loading>
  <div class="loading-box"><span class="loader primary"></span></div>
</ng-template>
