import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject, merge, Observable, of } from "rxjs";
import {
	distinctUntilChanged,
	filter,
	first,
	map,
	shareReplay,
	switchMap,
	tap
} from "rxjs/operators";
import {
	BiAccount,
	Connection,
	ConnectionState,
	Project
} from "../../app.interface";
import { config } from "../../config";
import { errorIconsResume, errorTheme } from "../utils/error-messages";
import { BudgetinsightService } from "./budgetinsight/budgetinsight.service";
import { ContractService } from "./contract/contract.service";
import { UserService } from "./user/user.service";

export interface ConnectionsWithIssue extends Connection {
	message?: string;
	icon?: string;
	color?: string;
}
export const PROJECT_OPTIONS: { label: string; value: Project }[] = [
	{
		label: "Préparer ma retraite",
		value: "retraite"
	},
	{
		label: "Epargner pour l'éduction de mes enfants",
		value: "education"
	},
	{
		label: "Préparer un achat immobilier",
		value: "immo"
	},
	{
		label: "Constituer une épargne de précaution",
		value: "precaution"
	},
	{
		label: "Léguer un capital",
		value: "legue"
	},
	{
		label: "Valoriser mon capital",
		value: "valorisation"
	}
];

@Injectable({
	providedIn: "root"
})
export class AccountService {
	private css = "color:#fb7e41;font-weight:bold";
	private readonly _showConnectionsIssues: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		config.global.popin.failedConnection
	);

	public readonly showConnectionsIssue$: Observable<boolean> = this._showConnectionsIssues.asObservable();

	public get showConnectionsIssues(): boolean {
		return this._showConnectionsIssues.getValue();
	}

	public set showConnectionsIssues(val: boolean) {
		this._showConnectionsIssues.next(val);
	}
	// BI Bank Connections

	public refreshConnections() {
		this.BI.refresh();
	}
	private _connections: BehaviorSubject<Connection[]> = new BehaviorSubject<
		Connection[]
	>(null);

	public connection$: Observable<Connection[]> = merge(
		this.BI.refresh$.pipe(filter(r => r > 0)),
		this.USER.profile$
	).pipe(
		switchMap(() =>
			this._connections.getValue()
				? this._connections.asObservable()
				: this.BI.listConnections()
		),
		map(connections =>
			connections.map(connection =>
				connection.error
					? {
							...connection,
							...errorTheme(connection.error, connection.bank.name)
					  }
					: connection
			)
		),
		tap(connections => this._connections.next(connections)),
		// tap(connections =>
		// 	console.log("%c[ACCOUNT] connection$ => ", this.css, connections)
		// ),
		shareReplay()
	);

	public connectionsWithIssue$: Observable<
		ConnectionsWithIssue[]
	> = this.connection$.pipe(
		map(connections => connections.filter(({ error }) => !!error)),
		tap(connections => {
			const errors = connections.map(({ error }) => error);
			const errordetails = errorIconsResume([...errors, "actionNeeded"]);
			//	console.log("errordetails: ", errordetails);
		})
		// tap(connections =>
		// 	console.log(
		// 		"%c[ACCOUNT] connectionsWithIssue$ => ",
		// 		this.css,
		// 		connections
		// 	)
		// )
	);
	public connectionIssuesIcon$: Observable<
		{
			icon: string;
			color: string;
			errortypes: ConnectionState[];
			count: number;
		}[]
	> = this.connectionsWithIssue$.pipe(
		map(connections => connections.map(({ error }) => error)),

		//	tap(errors => console.log("errors: ", errors)),
		map(errors => errorIconsResume([...errors])) //, "SCARequired","actionNeeded" (test)
		//	tap(errordetails => console.log("errordetails: ", errordetails))
	);
	private readonly _selectedConnectionId: BehaviorSubject<number> = new BehaviorSubject<number>(
		null
	);
	public get selectedConnectionId(): number {
		return this._selectedConnectionId.getValue();
	}
	public set selectedConnectionId(val: number) {
		if (this.selectedConnectionId === val) return;
		//  this.accoun
		this._selectedConnectionId.next(val);
	}

	public selectedConnection$: Observable<Connection> = this._selectedConnectionId
		.asObservable()
		.pipe(
			switchMap(connectionId =>
				this.connection$.pipe(
					map((connections: Connection[]) => {
						connectionId =
							typeof connectionId === "string"
								? parseInt(connectionId)
								: connectionId;
						return connections.find(({ id }) => id === connectionId);
					})
				)
			),
			shareReplay()
		);

	// // BI account
	private readonly _selectedAccountId: BehaviorSubject<number> = new BehaviorSubject<number>(
		null
	);
	public selectedAccountId$: Observable<number> = this._selectedAccountId.asObservable();
	public get selectedAccountId(): number {
		return this._selectedAccountId.getValue();
	}
	public set selectedAccountId(val: number) {
		if (this.selectedAccountId === val) return;
		this._selectedAccountId.next(val);
	}
	public selectedAccount$: Observable<BiAccount> = this.selectedAccountId$.pipe(
		switchMap(accountId =>
			this.accountsList$.pipe(
				map((accounts: BiAccount[]) => {
					accountId =
						typeof accountId === "string" ? parseInt(accountId) : accountId;
					return accounts.find(({ id }) => id === accountId);
				})
			)
		),
		shareReplay()
	);

	private readonly _loadingAccountList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public readonly loadingAccountList$: Observable<boolean> = this._loadingAccountList.asObservable();

	private readonly _updateAccount: BehaviorSubject<BiAccount> = new BehaviorSubject<BiAccount>(
		null
	);

	public set updateAccount(account: BiAccount) {
		this._updateAccount.next(account);
	}

	public accountsList$: Observable<BiAccount[]> = this.selectedConnection$.pipe(
		filter(connection => !!connection),
		map(({ id }) => id),
		distinctUntilChanged(),
		switchMap(id => this.getAccountListByConnectionId(id)),
		filter(({ all_accounts }) => !!all_accounts),
		map(({ all_accounts }) => all_accounts),
		switchMap(accountList =>
			this._updateAccount.asObservable().pipe(
				map(updatedAccount => {
					if (!updatedAccount?.id) return accountList;
					const i = accountList.findIndex(({ id }) => id === updatedAccount.id);
					if (i === -1) return accountList;
					accountList[i] = updatedAccount;
					//	console.log("> accountList: ", accountList);
					return accountList;
				})
			)
		),
		shareReplay()
	);

	constructor(
		private BI: BudgetinsightService,
		private CONTRACT: ContractService,
		private USER: UserService,
		private apollo: Apollo
	) {}
	public getAccountById(accountId: number): Observable<BiAccount> {
		accountId = typeof accountId === "string" ? parseInt(accountId) : accountId;
		return this.accountsList$.pipe(
			map((list: BiAccount[]) => list.find(({ id }) => id === accountId))
		);
	}
	public getConnectionById(connectionId: number): Observable<Connection> {
		connectionId =
			typeof connectionId === "string" ? parseInt(connectionId) : connectionId;
		return this.connection$.pipe(
			map((list: Connection[]) => list.find(({ id }) => id === connectionId))
		);
	}
	public deleteConnection(connectionId) {
		return this.BI.deleteConnection(connectionId).pipe(
			first(),
			switchMap(() => this.CONTRACT.deleteContract(connectionId))
		);
	}

	private _accountsListByBank: any = {};
	public getAccountsFromBank(param: string | number | object): Observable<any> {
		this._loadingAccountList.next(true);
		let BIMethod =
			typeof param !== "object"
				? "listAccountsByConnectionId"
				: "listAccountsByAccountData";

		return this.BI[BIMethod](param).pipe(
			filter((data: any) => !!data),
			first(),
			map((data: any) => ({
				...data,
				all_accounts: data.all_accounts?.filter(
					({ type }) =>
						type === "lifeinsurance" ||
						type === "perco" ||
						type === "pee" ||
						type === "savings" ||
						type === "unknown" ||
						type === "market"
				)
			})),
			tap(() => this._loadingAccountList.next(false)),
			shareReplay()
		);
	}
	public getAccountListByConnectionId(
		connectionId: string | number,
		reload?: boolean
	): Observable<any> {
		if (!reload && this._accountsListByBank[connectionId])
			return of(this._accountsListByBank[connectionId]);

		this._loadingAccountList.next(true);
		return this.BI.listAccountsByConnectionId(connectionId).pipe(
			filter((data: any) => !!data),
			first(),
			map((data: any) => ({
				...data,
				all_accounts: data.all_accounts?.filter(
					({ type }) =>
						type === "lifeinsurance" ||
						type === "perco" ||
						type === "pee" ||
						type === "savings" ||
						type === "unknown" ||
						type === "market"
				)
			})),
			tap(() => this._loadingAccountList.next(false)),
			tap(accounts => (this._accountsListByBank[connectionId] = accounts)),
			shareReplay()
		);
	}
	public getAccount(bankId?, accountId?) {
		bankId = bankId ?? this.selectedConnectionId;
		accountId = accountId ?? this.selectedAccountId;

		return this.BI.getAccount(bankId, accountId);
	}

	public setBiToken(access_token: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation setBiToken($access_token: String!) {
						setBiToken(access_token: $access_token)
					}
				`,
				variables: {
					access_token: access_token
				}
			})
			.pipe(
				map(result => {
					//	console.log(result);
					return result;
				})
			);
	}
}
