import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
	selector: "boost-submit-button",
	template: `
		<button class="btn {{ theme }}" [disabled]="form.invalid && form.touched">
			<span class="loader {{ loading ? 'show' : 'hide' }}"></span>
			<span>{{ label }}</span>
		</button>
	`
})
export class BoostSubmitButtonComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() label: string;
	@Input() loading: boolean;
	@Input() theme: string;

	constructor() {}
	ngOnInit() {}
}
