import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject, Observable, of } from "rxjs";
import { first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { User } from "src/app/app.interface";
import { getMe, getProfile } from "../../graphql/queries/User";
import { AuthenticationService } from "../authentication/authentication.service";
import { BudgetinsightService } from "../budgetinsight/budgetinsight.service";
import { objectDiff } from "../utils.service";

export const CSP_LIST: string[] = [
	"Agriculteurs exploitants",
	"Artisans, commerçants et chefs d’entreprise",
	"Cadres et professions intellectuelles supérieures",
	"Professions Intermédiaires",
	"Employés",
	"Ouvriers"
];
export const AGES_LIST: string[] = ["18-25", "25-39", "40-65", "65+"];

@Injectable({ providedIn: "root" })
export class UserService {
	private css = "color:#2980b9;font-weight:bold";

	constructor(
		private apollo: Apollo,
		private BI: BudgetinsightService,
		private AUTH: AuthenticationService
	) {}

	private readonly _currentUser: BehaviorSubject<User> =
		new BehaviorSubject<User>(null);

	public currentProfile$: Observable<User> = this.AUTH.isAuthenticated$.pipe(
		switchMap(isAuthenticated => {
			if (isAuthenticated)
				return this.getProfile().pipe(map(({ data }) => data?.me));
			else return of(null);
		}),
		tap(user => this._currentUser.next(user))
	);
	public profile$: Observable<User> = this.currentProfile$.pipe(
		//		tap(me => (this.BI.token = me?.biToken || null)),
		//	tap(me => console.log("%cprofile  : ", this.css, me)),
		shareReplay()
	);

	public getId() {
		return localStorage.getItem("userId");
	}

	public getOid() {
		return localStorage.getItem("userOid");
	}

	public getMe() {
		return this.apollo.watchQuery<any>({
			query: getMe,
			fetchPolicy: "network-only"
		}).valueChanges;
	}

	private getProfile() {
		return this.apollo.watchQuery<any>({
			query: getProfile,
			fetchPolicy: "network-only"
		}).valueChanges;
	}

	public editUser(user: Partial<User>) {
		const dataRef = this._currentUser.getValue();
		const differencies = objectDiff(user, dataRef).length > 0;

		if (!dataRef || !user || !differencies) return of(null);

		const { id, nickname, email, csp, age } = this._currentUser.getValue();
		const dataWithChanges = { id, nickname, email, csp, age, ...user };

		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveUser(
						$id: ID!
						$nickname: String
						$email: String
						$password: String
						$csp: String
						$age: String
					) {
						saveUser(
							id: $id
							nickname: $nickname
							email: $email
							password: $password
							csp: $csp
							age: $age
						)
					}
				`,
				variables: {
					...dataWithChanges
				}
			})
			.pipe(
				tap(res => console.log("editUser: " + res)),
				first()
			);
	}
	// TODO :  remove ?
	public edit(params: {
		id: string;
		nickname: string;
		email: string;
		password: string;
		csp: string;
		age: string;
	}) {
		console.log(
			"UserService > edit : ",
			params.id,
			params.nickname,
			params.email,
			params.password,
			params.age,
			params.csp
		);
		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveUser(
						$id: ID!
						$nickname: String
						$email: String
						$password: String
						$csp: String
						$age: String
					) {
						saveUser(
							id: $id
							nickname: $nickname
							email: $email
							password: $password
							csp: $csp
							age: $age
						)
					}
				`,
				variables: {
					id: params.id,
					nickname: params.nickname,
					email: params.email,
					password: params.password,
					csp: params.csp,
					age: params.age
				}
			})
			.pipe(
				map(result => {
					console.log(result);
					return result;
				})
			);
	}

	public editCspAge(params: { id: string; csp: string; age: string }) {
		console.log("UserService > edit : ", params.id, params.age, params.csp);
		return this.apollo
			.mutate({
				mutation: gql`
					mutation saveUser($id: ID!, $csp: String, $age: String) {
						saveUser(id: $id, csp: $csp, age: $age)
					}
				`,
				variables: {
					id: params.id,
					csp: params.csp,
					age: params.age
				}
			})
			.pipe(
				map(result => {
					console.log(result);
					//		this.biService.refresh();
					return result;
				})
			);
	}

	public impersonate(email: string) {
		return this.apollo
			.mutate({
				mutation: gql`
					mutation impersonate($email: String!) {
						impersonate(email: $email) {
							token
						}
					}
				`,
				variables: {
					email: email
				}
			})
			.pipe(
				map((result: any) => {
					localStorage.setItem("currentUser", result.data.impersonate.token);
					console.log(result);
					return result;
				})
			);
	}
}
