import { environment } from "src/environments/environment";

const RISK_COLORS = [
	"#2095F2",
	"#01BBD3",
	"#8BC24B",
	"#FFC10A",
	"#FF5723",
	"#C02D36",
	"#571929"
];
export const COMPARE_COLORS = [
	"#405a99",
	"#9ad251",
	"#f87c51",
	"#d84355",
	"#ffcb00",
	"#32bde9",
	"#2c9e07",
	"#5400c1"
];

export function getRiskColor(risk) {
	if (!risk || isNaN(risk) || risk === null || risk === 0) return "#4675b8";
	//'#bcbcbc'
	else return RISK_COLORS[risk - 1];
}
export function getRiskColorRgba(risk) {
	if (!risk || isNaN(risk) || risk === null || risk === 0)
		return "rgba(0,0,0,1)";
	else return `rgba(${hexToRgb(RISK_COLORS[risk - 1]).join(",")},1)`;
}
export function riskShade(risk, amt) {
	return colorShade(getRiskColor(risk), amt);
}

export function colorShade(col, amt) {
	col = col.slice(1);

	const num = parseInt(col, 16);

	var r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	var b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	var g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return "#" + (g | (b << 8) | (r << 16)).toString(16);
}
export function hexToRgb(hex) {
	return hex
		.replace(
			/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
			(m, r, g, b) => "#" + r + r + g + g + b + b
		)
		.substring(1)
		.match(/.{2}/g)
		.map(x => parseInt(x, 16));
}
export function rgbToHex(r, g, b) {
	return (
		"#" +
		[r, g, b]
			.map(x => {
				const hex = x.toString(16);
				return hex.length === 1 ? "0" + hex : hex;
			})
			.join("")
	);
}
export function truncate(str: string, limit: number) {
	if (str.length <= limit) return str;
	return str.slice(0, limit) + "...";
}

export const toInt = (val: number | string): number =>
	typeof val === "string" ? (parseInt(val) as number) : (val as number);

export const displayValue = (x: number): string => {
	const roundValue = x.toFixed(2);
	var parts = roundValue.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
	return parts.join(",&nbsp;") + "&nbsp;€";
};

export const formatStringForSearch = (str: string): string => {
	return str
		.trim()
		.toLowerCase()
		.normalize("NFD")
		.replace(/\p{Diacritic}/gu, "");
};

export const sortBy = (arr: any[], key) => {
	const sortBy = key => {
		return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
	};
	return arr?.concat().sort(sortBy("name")) || [];
};

export const objectDiff = (newData: any, dataRef: any): string[] => {
	// return newdata key list  of differents params
	return Object.keys(newData).filter(key => newData[key] !== dataRef[key]);
};

export const cleanDataFrom = (obj: any): any => {
	// delete invalid params in obj
	return Object.keys(obj)
		.filter(key => !!obj[key])
		.reduce((res, key) => ((res[key] = obj[key]), res), {});
};

export const uniqObjectArray = (arr: any[]) =>
	arr.filter(
		(element, index) =>
			arr.findIndex(
				step => JSON.stringify(element) === JSON.stringify(step)
			) === index
	);

export const minBy = (collection, key) => {
	const select = (a, b) => (a[key] <= b[key] ? a : b);
	return collection.reduce(select, {});
};

export const maxBy = (collection, key) => {
	const select = (a, b) => (a[key] >= b[key] ? a : b);
	return collection.reduce(select, {});
};

export const bankLogoUrl = (uuid: string): string => {
	return `${environment.biAPIUrl}/logos/${uuid}-thumbnail.webp`;
};
