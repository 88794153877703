import { Component, Input } from "@angular/core";

@Component({
	selector: "issue-icon",
	styleUrls: ["./issue-icon.component.scss"],
	template: `
		<span
			class="material-icons{{
				count || issue?.count ? ' count' : '-outlined '
			}} {{ color || issue?.color }} {{ icon || issue?.icon }}"
		>
			{{ icon || issue?.icon }}
		</span>
		<strong class="{{ color || issue?.color }}" *ngIf="count || issue?.count">{{
			count || issue.count
		}}</strong>
	`
})
export class IssueIconComponent {
	@Input() issue;
	@Input() color: string;
	@Input() icon: string;
	@Input() count: number;
	constructor() {}
}
